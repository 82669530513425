/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Wrapper from '../components/Wrapper';

const TermsAndConditionsPage = () => (
  <Layout>
    <SEO title="Home" />
    <Section title="Refund policy">
      <Wrapper>
        <div
          sx={{
            p: [3, 4],
            background: "white",
            borderRadius: "3px",
            boxShadow: "0 3px 10px 0 rgba(76, 82, 103, 0.1)",
          }}
        >
          <p>
            Since our Website offers non-tangible, irrevocable goods we do not
            provide refunds after the product is purchased, which you
            acknowledge prior to purchasing any product on the Website. Please
            make sure that you've carefully read service description before
            making a purchase.
          </p>
          <h2>Contacting us</h2>
          <p>
            If you would like to contact us concerning any matter relating to
            this Refund Policy, you may send an email to <a href="mailto:support@kongrads.com">support@kongrads.com</a>
          </p>
          <p>This document was last updated on April 28, 2020</p>
        </div>
      </Wrapper>
    </Section>
  </Layout>
)

export default TermsAndConditionsPage
